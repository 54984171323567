import { PixelColor } from '@/components/PixelAnimation.vue'

export const useAnimationDataPromisesMap = () =>
  useState<Partial<Record<PixelColor, Promise<any>>>>(
    'animation-data-promises-map',
    () => ({})
  )

export const useAnimationDisabled = () =>
  useState('animation-disabled', (): boolean => false)
